import { notification } from 'antd';
import { ActionType } from '../reducers/user';
import { API_URL, API_AUTH_URL } from '../utils/constants';
import { store } from '../reducers';
import { stringify } from 'query-string';
import axios from 'axios';
import { userAction } from '../actions/user';

const DEFAULT_ERROR = 'Something went wrong. Please try again!';

const onError = async ({ response }) => {
  if (response) {
    const { code, data, errorCodeText, status, error } = response;
    const errorCode = status || code;
    if (errorCode === 0) {
      store.dispatch({
        type: ActionType.MAINTENANCE,
      });
    } else if (errorCode === 401) {
      await userAction.signOut();
    } else if (errorCode < 500) {
      notification.error({
        key: 'axios',
        message: data?.message || data?.errors?.[0]?.message || error?.code || DEFAULT_ERROR,
      });
    } else {
      notification.error({
        key: 'axios',
        message: `${errorCode} - ${
          errorCodeText || data?.message || data?.errors?.[0]?.message || error?.code || DEFAULT_ERROR
        }`,
      });
    }
  } else {
    notification.error({
      key: 'axios',
      message: 'Cannot connect to Server',
    });
  }
  return Promise.reject(response);
};

const beforeRequest = (config) => {
  const { accessToken } = store.getState().user;
  if (accessToken && !config?.noAuthorization) {
    Object.assign(config.headers, {
      Authorization: `Bearer ${accessToken}`,
    });
  }
  if (config.data instanceof FormData) {
    Object.assign(config.headers, { 'Content-Type': 'multipart/form-data' });
  }
  return config;
};

const client = axios.create({
  baseURL: API_URL,
  paramsSerializer: (params) => stringify(params, { arrayFormat: '' }),
});
client.interceptors.request.use(beforeRequest);

// eslint-disable-next-line no-shadow
[client].forEach((client) => {
  client.interceptors.response.use(({ data: response, config }) => {
    const { error } = response;
    if (
      !error?.code ||
      error?.code === 'ERROR_CODE_SUCCESS' ||
      config?.isIgnoreError ||
      config?.allowErrors?.includes(error?.code)
    )
      return response;
    if (error?.code === 'ERROR_CODE_UNAUTHORIZED') {
      userAction.signOut();
    } else {
      notification.error({
        key: 'axios',
        message: error?.message || error?.code || DEFAULT_ERROR,
      });
    }
    // eslint-disable-next-line prefer-promise-reject-errors
    return Promise.reject({});
  }, onError);
});

const beforeRequestAuth = (config) => {
  var username = 'super_admin_algo';
  var password = 'xgUjwi6Tc661KqLbkT0';
  var basicAuth = 'Basic ' + btoa(username + ':' + password);
  Object.assign(config.headers, {
    Authorization: `${basicAuth}`,
  });
  const { accessToken } = store.getState().user;
  if (accessToken && !config?.noAuthorization) {
    Object.assign(config.headers, {
      Authorization: `Bearer ${accessToken}`,
    });
  }
  if (config.data instanceof FormData) {
    Object.assign(config.headers, { 'Content-Type': 'multipart/form-data' });
  }
  return config;
};

const auth = axios.create({
  baseURL: API_AUTH_URL,
  paramsSerializer: (params) => stringify(params, { arrayFormat: '' }),
});
auth.interceptors.request.use(beforeRequestAuth);

// eslint-disable-next-line no-shadow
[auth].forEach((client) => {
  client.interceptors.response.use(({ data: response, config }) => {
    const { error } = response;
    if (error?.code === 'ERROR_CODE_SUCCESS' || config?.isIgnoreError || config?.allowErrors?.includes(error?.code))
      return response;
    if (error?.code === 'ERROR_CODE_UNAUTHORIZED') {
      userAction.signOut();
    } else {
      notification.error({
        key: 'axios',
        message: error?.message || error?.code || DEFAULT_ERROR,
      });
    }
    // eslint-disable-next-line prefer-promise-reject-errors
    return Promise.reject({});
  }, onError);
});

export { client, auth };
