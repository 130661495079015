import { client } from './axios';

const fetchBaseProducts = (body) => client.post(`/merchant.v1.MerchantService/ListProductBase`, body);
const fetchMerchantProducts = (body) => client.post(`/merchant.v1.MerchantService/ListProduct`, body);
const createMerchantProduct = (body) => client.post(`/merchant.v1.MerchantService/CreateProduct`, body);
const updateMerchantProduct = (body) => client.post(`/merchant.v1.MerchantService/UpdateProduct`, body);
const fetchUsers = (body) => client.post(`/merchant.v1.MerchantService/ListMerchantUser`, body);
const updateUser = (body) => client.post(`/merchant.v1.MerchantService/UpdateUser`, body);
const updateUserBalance = (body) => client.post(`/merchant.v1.MerchantService/UpdateUserBalance`, body);
const fetchUserTransactions = (body) => client.post(`/merchant.v1.MerchantService/ListUserTransaction`, body);
const fetchTransactions = (body) => client.post(`/merchant.v1.MerchantService/ListTransaction`, body);
const fetchCampaigns = (body) => client.post(`/merchant.v1.MerchantService/ListCampaign`, body);
const createCampaign = (body) => client.post(`/merchant.v1.MerchantService/CreateCampaign`, body);
const updateCampaign = (body) => client.post(`/merchant.v1.MerchantService/UpdateCampaign`, body);
const fetchCampaignCodes = (body) => client.post(`/merchant.v1.MerchantService/ListCampaignCode`, body);

const authMerchant = (body) => client.post(`/merchant.v1.MerchantService/MerchantLogin`, body);


const algoService = {
  fetchBaseProducts,
  fetchMerchantProducts,
  createMerchantProduct,
  updateMerchantProduct,
  fetchUsers,
  fetchUserTransactions,
  fetchTransactions,
  updateUser,
  updateUserBalance,
  fetchCampaigns,
  createCampaign,
  updateCampaign,
  fetchCampaignCodes,
};

export default algoService;
