import {auth, client} from './axios';

const fetchApps = (body) => auth.post(`/FetchApp`, body);
const createApp = (body) => auth.post(`/CreateApp`, body);
const updateApp = (body) => auth.post(`/UpdateApp`, body);
const fetchRoles = (body) => auth.post(`/FetchRole`, body);
const createRole = (body) => auth.post(`/CreateRole`, body);
const updateRole = (body) => auth.post(`/UpdateRole`, body);
const fetchUsers = (body) => auth.post(`/FetchUser`, body);
const createUser = (body) => auth.post(`/CreateUser`, body);
const updateUser = (body) => auth.post(`/UpdateUser`, body);
const fetchServices = (body) => auth.post(`/FetchService`, body);
const createService = (body) => auth.post(`/CreateService`, body);
const updateService = (body) => auth.post(`/UpdateService`, body);
const fetchPaths = (body) => auth.post(`/FetchPath`, body);
const createPath = (body) => auth.post(`/CreatePath`, body);
const updatePath = (body) => auth.post(`/UpdatePath`, body);
const fetchPolicies = (body) => auth.post(`/FetchPolicy`, body);
const createPolicy = (body) => auth.post(`/CreatePolicy`, body);
const updatePolicy = (body) => auth.post(`/UpdatePolicy`, body);
const login = (body) => client.post(`/merchant.v1.MerchantService/MerchantLogin`, body);
const reloadEnforcer = () => auth.post(`/ReloadEnforcer`, {});
// const fetchMe = () => auth.post(`/Me`, {});
const fetchMe = () => client.post(`/auth.v1.ManagementAuthService/Me`, {});

const authService = {
  fetchMe,
  reloadEnforcer,
  login,
  fetchPolicies,
  createPolicy,
  updatePolicy,
  fetchPaths,
  createPath,
  updatePath,
  fetchServices,
  createService,
  updateService,
  fetchUsers,
  createUser,
  updateUser,
  fetchRoles,
  createRole,
  updateRole,
  fetchApps,
  createApp,
  updateApp,
};

export default authService;
