const PERMISSIONS = {
  Login: '/auth.v1.ManagementAuthService/Login',
  Me: '/auth.v1.ManagementAuthService/Me',
  FetchBaseProducts: '/merchant.v1.MerchantService/ListProductBase',
  FetchMerchantProducts: '/merchant.v1.MerchantService/ListProduct',
  CreateMerchantProduct: '/merchant.v1.MerchantService/CreateProduct',
  UpdateMerchantProduct: '/merchant.v1.MerchantService/UpdateProduct',
  FetchTransactions: '/merchant.v1.MerchantService/ListTransaction',
  FetchUserTransactions: '/merchant.v1.MerchantService/ListUserTransaction',
  FetchUsers: '/merchant.v1.MerchantService/ListMerchantUser',
  UpdateUser: '/merchant.v1.MerchantService/UpdateUser',
  UpdateUserBalance: '/merchant.v1.MerchantService/UpdateUserBalance',
  FetchCampains: '/merchant.v1.MerchantService/ListCampaign',
  CreateCampaign: '/merchant.v1.MerchantService/CreateCampaign',
  UpdateCampaign: '/merchant.v1.MerchantService/UpdateCampaign',
  FetchCampaignCodes: '/merchant.v1.MerchantService/ListCampaignCode',
};

const PERMISSIONS_PAGE = {
  '/products': [PERMISSIONS.FetchBaseProducts, PERMISSIONS.FetchMerchantProducts],
  '/products/base-products': [PERMISSIONS.FetchBaseProducts],
  '/products/merchant-products': [
    PERMISSIONS.FetchMerchantProducts,
    PERMISSIONS.CreateMerchantProduct,
    PERMISSIONS.UpdateMerchantProduct,
  ],
  '/transactions': [PERMISSIONS.FetchTransactions],
  '/accounts': [PERMISSIONS.FetchUsers, PERMISSIONS.FetchUserTransactions],
  '/accounts/users': [PERMISSIONS.FetchUsers, PERMISSIONS.UpdateUser, PERMISSIONS.UpdateUserBalance],
  '/accounts/transactions': [PERMISSIONS.FetchUsers],
  '/promotions': [PERMISSIONS.FetchCampains, PERMISSIONS.FetchCampaignCodes],
  '/promotions/campaigns': [PERMISSIONS.FetchCampains, PERMISSIONS.CreateCampaign, PERMISSIONS.UpdateCampaign],
  '/promotions/codes': [PERMISSIONS.FetchCampaignCodes],
};

export { PERMISSIONS, PERMISSIONS_PAGE };
