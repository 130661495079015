/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useCallback, useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import { COOKIE_NAME } from './utils/constants';
import { userAction } from './actions/user';
import LoadingIndicator from './components/LoadingIndicator';
import PrivateRoute from './PrivateRoute';
import './App.scss';
import ScrollToTop from './components/ScrollToTop';
import Login from './pages/Login/Loadable';

import BaseProducts from './pages/Products/BaseProducts/Loadable';
import MerchantProducts from './pages/Products/MerchantProducts/Loadable';
import Users from './pages/Accounts/Users/Loadable';
import UserTransactions from './pages/Accounts/Transactions/Loadable';
import Transactions from './pages/Transactions/Loadable';
import Campaigns from './pages/Promotions/Campaigns/Loadable';
import Codes from './pages/Promotions/Codes/Loadable';

function App() {
  const [isReadyUser, setIsReadyUser] = useState(false);

  const loadUser = useCallback(async () => {
    try {
      const profileJson = localStorage.getItem(COOKIE_NAME);
      if (profileJson) {
        const profile = JSON.parse(profileJson);
        await userAction.signIn(profile);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setIsReadyUser(true);
    }
  }, []);

  useEffect(() => {
    loadUser();
  }, []);

  if (!isReadyUser)
    return (
      <div className='App'>
        <LoadingIndicator />
      </div>
    );

  return (
    <div className='App'>
      <ScrollToTop />
      <Switch>
        <Route path='/login' component={Login} />
        <PrivateRoute path='/products/base-products' component={BaseProducts} />
        <PrivateRoute path='/products/merchant-products' component={MerchantProducts} />
        <PrivateRoute path='/accounts/users' component={Users} />
        <PrivateRoute path='/accounts/transactions' component={UserTransactions} />
        <PrivateRoute path='/transactions' component={Transactions} />
        <PrivateRoute path='/promotions/campaigns' component={Campaigns} />
        <PrivateRoute path='/promotions/codes' component={Codes} />
        <Route path=''>
          <Redirect to='/products/merchant-products' />
        </Route>
      </Switch>
    </div>
  );
}

export default App;
